import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import ChooseYourSetting from "./component/ApplicationPreview/ChooseYourSetting";
import SelectYourDiamond from "./component/ApplicationPreview/SelectYourDiamond";
import CompleteYourRing from "./component/ApplicationPreview/CompleteYourRing";
import Layout from "./component/Layout";
// import ChooseSettingItemDetails from "./component/ApplicationPreview/ChooseSettingItemDetails";
import Compare from "./component/common/SelectYourDiamond/Compare";
import Request from "./component/common/SelectYourDiamond/Request";
import ColorPanel from "./component/ColorPanel";
import MinedDiamond from "./component/common/SelectYourDiamond/MinedDiamond";
import LabGrown from "./component/common/SelectYourDiamond/LabGrown";
import ViewDiamondDetails from "./component/common/SelectYourDiamond/ViewDiamondDetails";
import { useSelector } from "react-redux";
import { I18Provider, LOCALES } from "./i18n";
import { useCookies } from "react-cookie";

const PrivateRoute = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const App = () => {
  const fontStyle = useSelector(
    (state) => state.previewColorReducer.fontFamily
  );
  const [cookies] = useCookies(["lngCookie"]);

  const language = cookies.lngCookie;
  const currentLocale = Object.keys(LOCALES).filter((id) => {
    if (LOCALES[id] === (language ? language.toUpperCase() : LOCALES.ENGLISH))
      return LOCALES[id];
  });
  return (
    <div style={{ fontFamily: fontStyle ? fontStyle : "LexendDeca-Regular" }}>
      <React.Fragment>
        <I18Provider locale={currentLocale}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/selectYourDiamond"
                element={<SelectYourDiamond />}
              />
              <Route exact path="/" element={<PrivateRoute />}>
                <Route path="/Mined" element={<MinedDiamond />} />
                <Route path="/Lab" element={<LabGrown />} />
                {/* <Route path="/Fancy" element={<FancyTab />} /> */}

                <Route path="/Request" element={<Request />} />
                <Route path="/Compare" element={<Compare />} />
                <Route
                  path="/completeyourring"
                  element={<CompleteYourRing />}
                />
                <Route
                  path="/viewDiamondDetails"
                  element={<ViewDiamondDetails />}
                />
                <Route
                  path="/chooseYourSetting"
                  element={<ChooseYourSetting />}
                />
              </Route>
            </Routes>
            <ColorPanel></ColorPanel>
          </BrowserRouter>
        </I18Provider>
      </React.Fragment>
    </div>
  );
};

export default App;
