import config from "./config";
import axios from "axios";
import { Cookies } from "react-cookie";
import useDecryptData from "../utils/DecryptData";

function GetDecryptToken() {
  const cookies = new Cookies();
  const DecryptData = useDecryptData();
  let EncryptToken = cookies.get("token");
  let finalToken = DecryptData(EncryptToken);
  return finalToken;
}

const addInterceptors = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const headerToken = GetDecryptToken();
      const urlParams = new URLSearchParams(window.location.search);
      const qtoken = urlParams.get("clientSecret");
      const dqtoken = useDecryptData(qtoken);
      if (headerToken) {
        config.headers["X-Authorized-Token"] = headerToken;
      } else {
        config.headers["X-Authorized-Token"] = dqtoken;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        window.location.href = "/";
      }
      return Promise.reject(error);
    }
  );
};

export const httpApps = axios.create({
  baseURL: config.AppsAPIURL,
  headers: {
    "Content-Type": "application/json,multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});

export const httpAdminCompanyLocation = axios.create({
  baseURL: config.AdminAPIURL,
  headers: {
    "Content-Type": "application/json,application/octet-stream",
    "Access-Control-Allow-Origin": "*",
  },
});

export const httpDealer = axios.create({
  baseURL: config.DealerAPIURL,
  headers: {
    "Content-Type": "application/json,application/octet-stream",
    "Access-Control-Allow-Origin": "*",
  },
});

[httpApps, httpAdminCompanyLocation, httpDealer].forEach(addInterceptors);
